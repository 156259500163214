@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');
@import '~rsuite/dist/rsuite.min.css'; // Rsuite styles
@import 'react-phone-input-2/lib/style.css'; // phone number input styles
@import "react-image-crop/src/ReactCrop.scss"; 
html,
body {
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
li {
  font-family: 'Roboto', sans-serif !important;
  margin: 0;
}

p,
strong {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

pre,
p,
a {
  font-size: 1rem;
}

p,
pre,
a {
  font-size: 1rem;
}

.renderer-core-progress-bar {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.dropdown__choiceWrapper {
  background-color: transparent !important;
  color: #000 !important;
}
.dropdown__choiceWrapper:hover,
.dropdown__choiceWrapper.selected {
  background-color: #000 !important;
  color: #fff !important;
}

.multipleChoice__optionWrapper {
  background-color: transparent !important;
  color: #000 !important;
}

.multipleChoice__optionKey {
  background-color: transparent !important;
  color: #000 !important;
}

.multipleChoice__optionWrapper:hover,
.multipleChoice__optionWrapper.selected {
  background-color: #000 !important;
  color: #fff !important;

  .multipleChoice__optionKey {
    background-color: #fff !important;
    color: #000 !important;
  }
}

.creed-drag-and-drop {
  .renderer-core-block-scroller {
    align-items: flex-start !important;
  }
  .renderer-components-field-content {
    max-width: 1100px !important;
  }
}
